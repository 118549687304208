var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"Imię","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(" Imię"),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"}],staticClass:"input",attrs:{"type":"text","placeholder":"Imię","autocomplete":"name"},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value}}}),_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"Nazwisko","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(" Nazwisko"),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"}],staticClass:"input",attrs:{"type":"text","placeholder":"Nazwisko","autocomplete":"family-name"},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value}}}),_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"E-mail","rules":"required|email-abstract"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(" Adres e-mail*"),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"input",attrs:{"type":"email","placeholder":"Twój adres e-mail","autocomplete":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"password","rules":"password|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(" Hasło*"),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"input",attrs:{"type":"password","placeholder":"Hasło","autocomplete":"new-password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"Powtórz hasło","rules":"required|password-confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(" Powtórz hasło*"),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.repassword),expression:"repassword"}],staticClass:"input",attrs:{"type":"password","placeholder":"Powtórz hasło","autocomplete":"new-password"},domProps:{"value":(_vm.repassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.repassword=$event.target.value}}}),_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"Numer telefonu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v(" Numer telefonu"),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],staticClass:"input",attrs:{"type":"text","placeholder":"Numer telefonu","autocomplete":"tel"},domProps:{"value":(_vm.phoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phoneNumber=$event.target.value}}})]),_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"input-wrapper"},[_c('label',{staticClass:"label"},[_vm._v(" Adres"),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"input",attrs:{"type":"text","placeholder":"Adres","autocomplete":"street-address"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}})])]),_c('br'),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"rules":"checkbox-required","name":"rodo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_c('div',{staticClass:"fake-input-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rodo),expression:"rodo"}],staticClass:"input",attrs:{"type":"checkbox","tabindex":"-1"},domProps:{"checked":Array.isArray(_vm.rodo)?_vm._i(_vm.rodo,null)>-1:(_vm.rodo)},on:{"change":function($event){var $$a=_vm.rodo,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.rodo=$$a.concat([$$v]))}else{$$i>-1&&(_vm.rodo=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.rodo=$$c}}}}),_c('div',{staticClass:"fake-input",class:{ 'fake-input--ff': _vm.isFirefox }}),_c('span',{staticClass:"checkbox-text"},[_vm._v(" Akceptuję "),_c('a',{attrs:{"href":"/regulamin"},on:{"click":function($event){$event.preventDefault();return _vm.onLinkClick('regulaminu')}}},[_vm._v("regulamin")]),_vm._v(" oraz "),_c('a',{attrs:{"href":"/polityka-prywatnosci"},on:{"click":function($event){$event.preventDefault();return _vm.onLinkClick('polityki prywatności')}}},[_vm._v("politykę prywatności")]),_vm._v(" *"),_c('br')])]),_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('ValidationProvider',{staticClass:"input-wrapper",attrs:{"name":"allowSend"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_c('div',{staticClass:"fake-input-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allowInfo),expression:"allowInfo"}],staticClass:"input",attrs:{"type":"checkbox","tabindex":"-1"},domProps:{"checked":Array.isArray(_vm.allowInfo)?_vm._i(_vm.allowInfo,null)>-1:(_vm.allowInfo)},on:{"change":function($event){var $$a=_vm.allowInfo,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allowInfo=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allowInfo=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allowInfo=$$c}}}}),_c('div',{staticClass:"fake-input",class:{ 'fake-input--ff': _vm.isFirefox }}),_c('span',{staticClass:"checkbox-text"},[_vm._v(" Wyrażam zgodę na otrzymywanie informacji na podany przeze mnie adres e‑mail. ")])]),_c('span',{staticClass:"errors"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_vm._v(" * Pola wymagane "),_c('div',{staticClass:"btn-wrapper"},[_c('button',{staticClass:"btn",attrs:{"type":"submit"}},[_vm._v("Załóż konto")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }